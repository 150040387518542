import cx from 'classnames';
import { useRouter } from 'next/router';
import { useContext, useCallback, useMemo, Fragment } from 'react';

import { useHandleInternalLinks } from '@superhuit/starterpack-hooks';
import { SectionProvider, ThemeContext } from '@superhuit/starterpack-context';
import { omit } from '@superhuit/starterpack-utils';
import { LocaleContext } from '@superhuit/starterpack-i18n';
import Blocks from '@/next-components/blocks';
import Container from '@/next-components/container';
import { Filters, ResultsInfo, ListShowsEvents } from '@/components/renders';
import { formatEventCard, formatFilters } from '@/lib/formaters';
import { EventsPageThemeControls } from './theming';
import { getStyles } from './styles.css';

const ARRAY_SIZE = 6;

export default function EventsPage({ node }) {
	const blocks = node?.blocksJSON ? JSON.parse(node.blocksJSON) : [];
	const blocksCta = blocks.filter(({ name }) => name === 'supt/section-ctas');
	const nbBlocksCta = useMemo(() => blocksCta.length, [blocksCta]);

	const handleInternalLinks = useHandleInternalLinks();
	const router = useRouter();

	// Merge: defaults + theme (variant base + variant active)
	const { theme } = useContext(ThemeContext);
	const blockTheme = theme?.['templates']?.[EventsPageThemeControls.slug]; // theme
	const finalTheme = omit(blockTheme, ['variants']);
	// console.log('::: Final Theme ::: ', finalTheme);

	const rootClass = cx('supt-eventsPage', getStyles(finalTheme));

	const { __t, locale } = useContext(LocaleContext);

	/**
	 * Format event dates
	 */
	const formatedEvents = useMemo(
		() => node.archive?.events?.nodes?.map(formatEventCard, locale) ?? [],
		[node.archive?.events]
	);

	/**
	 * Format filters
	 */
	const filters = useMemo(
		() => [
			{
				filter: 'seasons',
				items: formatFilters(node.archive.filters.seasons.nodes),
			},
		],
		[]
	);

	const selectedSeason = useMemo(() => {
		let season = null;
		const selected = node.archive.filters.seasons.nodes.find(
			(term) => term.isSelected
		);
		if (selected) season = selected;
		else {
			const current = node.archive.filters.seasons.nodes.find(
				(term) => term.isCurrent
			);
			if (current) season = current;
		}

		return formatFilters([season ?? null].filter(Boolean));
	}, []);

	const onFiltersChange = useCallback((values) => {
		let pushUri = undefined;

		if (values.length === 0) {
			pushUri =
				node.archive.filters.seasons.nodes.find(
					(term) => term.isCurrent
				)?.uri ?? undefined;
		} else {
			const newSelectedSeason = values.find((value) => value.filter);
			pushUri =
				node.archive.filters.seasons.nodes.find(
					(term) => term.id === newSelectedSeason.id
				)?.uri ?? undefined;
		}

		if (pushUri) router.push(pushUri);
	}, []);

	return (
		<>
			<Container className={rootClass} data-block="eventsPage">
				<div
					className="supt-eventsPage__inner"
					onClick={handleInternalLinks}
				>
					<SectionProvider variant="default">
						<Filters
							title={node.title}
							buttons={[]}
							filters={filters}
							selectedFilters={selectedSeason}
							onChange={onFiltersChange}
							initialActiveFilters={0}
						/>

						{/* List of events */}
						{formatedEvents.length > 0 ? (
							nbBlocksCta > 0 ? (
								blocksCta.map((cta, i) => {
									const start = i * ARRAY_SIZE;
									const end =
										i === nbBlocksCta - 1
											? undefined
											: start + ARRAY_SIZE;
									return (
										<Fragment key={i}>
											<ListShowsEvents
												events={formatedEvents.slice(
													start,
													end
												)}
											/>
											<Blocks
												blocks={[cta]}
												includes={
													/^supt\/section-ctas/g
												}
											/>
										</Fragment>
									);
								})
							) : (
								<ListShowsEvents events={formatedEvents} />
							)
						) : (
							<ResultsInfo
								number={0}
								onChange={(value) => setSelectedFilters(value)}
							></ResultsInfo>
						)}
					</SectionProvider>
				</div>
			</Container>
		</>
	);
}
